import * as React from "react"
import loadable from "@loadable/component"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import {
  Container,
  Flex,
  Box,
  Space,
  Heading,
  Text,
  Avatar,
  Button,
  Section,
} from "../components/ui"
import { avatar as avatarStyle } from "../components/ui.css"
import * as styles from "./blog-post.css"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { convertYoutubeURL, readingTime } from "../helper-functions"
import BlogToC from "./blog-toc"
import Hero from "./hero"
import { graphql } from "gatsby"
import ActivecampaignForm from "./activecampaign-form"
import { theme } from "../theme.css"
import StickyBox from "react-sticky-box"
import {
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share"
import LeadMagnet from "./lead-magnet"

const CustomIconList = loadable(() => import("./custom-icon-list"))
const FeedbackPreviewListSection = loadable(() =>
  import("./feedback-preview-list-section")
)
const CtaCard = loadable(() => import("./cta-card"))
const NumberedList = loadable(() => import("./numbered-list"))
const Video = loadable(() => import("./video"))
const CustomImageList = loadable(() => import("./custom-image-list"))

const randomNumber = (str) => {
  return `h${Math.floor(Math.random() * str.length * 1000)}`
}

function Share(props) {
  return (
    <Box>
      <Text variant="subhead">Teilen</Text>
      <Flex>
        <LinkedinShareButton
          url={`https://nicoleranke.ch/blog/${props.slug}/`}
          title={props.title}
          summary={props.excerpt.excerpt}
          source="https://nicoleranke.ch/"
        >
          <LinkedinIcon round size={32} />
        </LinkedinShareButton>
        <WhatsappShareButton
          url={`https://nicoleranke.ch/blog/${props.slug}/`}
          title={props.title}
        >
          <WhatsappIcon round size={32} />
        </WhatsappShareButton>
        <TwitterShareButton
          url={`https://nicoleranke.ch/blog/${props.slug}/`}
          title={props.title}
        >
          <TwitterIcon round size={32} />
        </TwitterShareButton>
        <FacebookShareButton
          url={`https://nicoleranke.ch/blog/${props.slug}/`}
          quote={props.title}
        >
          <FacebookIcon round size={32} />
        </FacebookShareButton>
        <PinterestShareButton
          url={`https://nicoleranke.ch/blog/${props.slug}/`}
          description={props.excerpt.excerpt}
          media={props.image.url}
        >
          <PinterestIcon round size={32} />
        </PinterestShareButton>
        <EmailShareButton
          url={`https://nicoleranke.ch/blog/${props.slug}/`}
          subject={props.title}
          body={props.excerpt.excerpt}
        >
          <EmailIcon round size={32} />
        </EmailShareButton>
      </Flex>
    </Box>
  )
}

function SideBar(props) {
  return (
    <StickyBox offsetTop={30} className={styles.stickyBox}>
      <Box className={styles.sidebarContent}>
        <BlogToC />
        <Space size={5} />
        <LeadMagnet />
        <Space size={5} />
        <Share {...props} />
        <Space size={5} />
      </Box>
    </StickyBox>
  )
}

function Author(props) {
  return (
    <Box>
      {props.author && (
        <Flex>
          {props.author.avatar &&
            (!!props.author.avatar.localFile.childImageSharp.gatsbyImageData ? (
              <Avatar
                {...props.author.avatar}
                image={
                  props.author.avatar.localFile.childImageSharp.gatsbyImageData
                }
              />
            ) : (
              <img
                src={props.author.avatar.url}
                alt={props.author.name}
                className={avatarStyle}
              />
            ))}
          <Flex variant={"column"} flexDirection={"start"} style={{ gap: 0 }}>
            <Flex gap={2}>
              <Text variant="bold">{props.author.name}</Text>
              <Text variant="body" style={{ marginBottom: 0 }}>
                Life-Coach
              </Text>
            </Flex>
            <Flex>
              <Text variant="body">
                Lesezeit:{" "}
                {readingTime(
                  documentToPlainTextString(JSON.parse(props.body.raw))
                )}{" "}
                Minuten
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

export default function BlogPost({ data }) {
  const props = data.contentfulBlogPost
  const richTextImages = {}
  const richTextEntrys = {}

  props.body.references.map((ref) => {
    if (ref.sys?.type === "Asset") {
      richTextImages[ref.contentful_id] = {
        image: ref.localFile.childImageSharp.gatsbyImageData,
        alt: ref.alt,
      }
    } else {
      richTextEntrys[ref.contentful_id] = ref
    }
  })
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => {
        return <Heading as="h1">{children}</Heading>
      },
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading as="h2" id={randomNumber(children[0])}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading as="h3" id={randomNumber(children[0])}>
          {children}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading as="h4" id={randomNumber(children[0])}>
          {children}
        </Heading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <Text as="p">{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const imageData = richTextImages[node.data.target.sys.id]
        const image = getImage(imageData.image)
        return (
          <div className="align-center">
            <Space size={6} />
            <GatsbyImage image={image} alt={imageData.alt} />
            <Space size={4} />
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const entry = richTextEntrys[node.data.target.sys.id]
        if (entry.sys.contentType.sys.id === "embedVideo") {
          return (
            <>
              <Space size={4} />
              <Video videoSrcURL={convertYoutubeURL(entry.videoUrl)} />
              <Space size={4} />
            </>
          )
        }
        if (entry.sys.contentType.sys.id === "customIconList") {
          return <CustomIconList {...entry} />
        }
        if (entry.sys.contentType.sys.id === "homepageLink") {
          return (
            <>
              <Space size={4} />
              <Button to={entry.href}>{entry.text}</Button>
              <Space size={4} />
            </>
          )
        }
        if (entry.sys.contentType.sys.id === "customImageList") {
          return (
            <>
              <Space size={4} />
              <CustomImageList {...entry} />
              <Space size={5} />
            </>
          )
        }
        if (entry.sys.contentType.sys.id === "numberedList") {
          return <NumberedList {...entry} />
        }
        if (entry.sys.contentType.sys.id === "ctaCard") {
          return (
            <>
              <Space size={4} />
              <CtaCard {...entry} />
              <Space size={4} />
            </>
          )
        }
      },
    },
  }
  return (
    <Layout {...props} description={props.excerpt?.excerpt}>
      <Hero
        image={props.image && props.image}
        textColor="light"
        h1={props.heroTitle}
        index={0}
      ></Hero>
      <Section background={theme.colors.white}>
        <Container width="narrow">
          <Box>
            <Flex alignItems={"start"} variant={"responsiveMediumReverse"}>
              <SideBar {...props} />
              <div>
                <Author {...props} />
                <Space size={5} />
                <div className={styles.blogPost} id="blogPostContent">
                  {documentToReactComponents(
                    JSON.parse(props.body.raw),
                    options
                  )}
                </div>
              </div>
            </Flex>
          </Box>
        </Container>
      </Section>
      {props.feedback && <FeedbackPreviewListSection {...props.feedback} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      date(formatString: "DD.MM.YYYY")
      category
      feedback {
        link {
          href
          text
        }
        title
        content {
          body
          date
          name
        }
      }
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        alt
        description
      }
      slug
      title
      heroTitle
      excerpt {
        excerpt
      }
      id
      author {
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          alt
          description
        }
        name
      }
      body {
        raw
        references {
          ...ContentfulAssetContent
          ...ContentfulCustomIconListContent
          ...ContentfulEmbedVideoContent
          ...ContentfulHomepageLinkContent
          ...ContentfulCustomImageListContent
          ...ContentfulNumberedListContent
          ...ContentfulCtaCardContent
        }
      }
    }
  }
`
