// https://nicole-ranke.de/selbstliebebuch/

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import {
  acAccoirdion,
  acForm,
  acPanel,
  acWrapper,
  acAccoirdionCaret,
  acImageWrapper,
} from "./lead-magnet.css"
import { Box, Button, Link, Space, SubmitButton } from "./ui"
import { graphql, StaticQuery } from "gatsby"

export default function LeadMagnet() {
  return (
    <Box className={acWrapper}>
      <StaticQuery
        query={graphql`
          query {
            contentfulNewsletterBox {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                description
              }
            }
          }
        `}
        render={(data) => {
          const imageData =
            data.contentfulNewsletterBox.image.localFile.childImageSharp
              .gatsbyImageData
          const image = getImage(imageData)
          return (
            <div className={acImageWrapper}>
              <GatsbyImage
                image={image}
                alt={data.contentfulNewsletterBox.image.description}
              />
            </div>
          )
        }}
      />
      <Space size={2} />
      <Button
        variant={"maxWidth"}
        to={"https://nicole-ranke.de/selbstliebebuch/"}
        target="_blank"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        Hier kostenlos herunterladen!
      </Button>
    </Box>
  )
}
