import * as React from "react"
import { tocLink, tocLinkActive, tocNav } from "./blog-toc.css"
import { Text } from "./ui"

const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([])

  React.useEffect(() => {
    const headingElements = Array.from(
      document
        .getElementById("blogPostContent")
        .querySelectorAll("h2[id], h3[id]")
    )

    const newNestedHeadings = getNestedHeadings(headingElements)
    setNestedHeadings(newNestedHeadings)
  }, [])

  return { nestedHeadings }
}

const getNestedHeadings = (headings) => {
  const nestedHeadings = []
  headings.map((heading, index) => {
    const { innerText: title, id } = heading
    if (heading.nodeName === "H2") {
      nestedHeadings.push({ title, id, items: [] })
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        title,
        id,
      })
    }
  })
  return nestedHeadings
}

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({})

  React.useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)

      const visibleHeadings = []
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key]
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
      })

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id)

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id)
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        )
        setActiveId(sortedVisibleHeadings[0].target.id)
      }
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: "-40px 0px -40% 0px",
    })

    const headingElements = Array.from(
      document.querySelectorAll("h2[id], h3[id]")
    )
    headingElements.forEach((element) => observer.observe(element))

    return () => observer.disconnect()
  }, [])
}

const Headings = ({ headings, activeId }) => (
  <ul>
    {headings.map((heading) => (
      <li
        key={heading.id}
        className={heading.id === activeId ? tocLinkActive : tocLink}
      >
        <div>
          <a
            href={`#${heading.id}`}
            onClick={(e) => {
              e.preventDefault()
              document.querySelector(`#${heading.id}`).scrollIntoView({
                behavior: "smooth",
              })
            }}
          >
            {heading.title}
          </a>
        </div>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li
                key={child.id}
                className={child.id === activeId ? tocLinkActive : tocLink}
              >
                <div>
                  <a
                    href={`#${child.id}`}
                    onClick={(e) => {
                      e.preventDefault()
                      document.querySelector(`#${child.id}`).scrollIntoView({
                        behavior: "smooth",
                      })
                    }}
                  >
                    {child.title}
                  </a>
                </div>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
)

export default function BlogToC() {
  const [activeId, setActiveId] = React.useState()
  const { nestedHeadings } = useHeadingsData()
  useIntersectionObserver(setActiveId)

  return (
    <>
      <Text variant="subhead">Inhaltsverzeichnis</Text>
      <nav className={tocNav} aria-label="Inhaltsverzeichnis">
        <Headings headings={nestedHeadings} activeId={activeId} />
      </nav>
    </>
  )
}
